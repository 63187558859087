<template>
  <div>
    <div class="columns">
      <div class="column content section">
        <div class="columns is-centered">
          <div class="column">
            <h2 class="has-text-centered">
              ACH request dashboard
            </h2>

            <v-client-table
              id="ach-table"
              ref="allAch"
              :columns="tableColumns"
              :options="tableOptions"
              :data="allAchDataFiltered"
            >

              <template
                slot="statusTranslated"
                slot-scope="allAchDataFiltered"
              >
                <span
                  v-if="allAchDataFiltered.row.statusTranslated==='Deleted'"
                  class="tag is-black has-text-weight-bold"
                >
                  {{ allAchDataFiltered.row.statusTranslated }}
                </span>
                <span
                  v-else-if="allAchDataFiltered.row.statusTranslated==='Rejected by ACH admin'"
                  class="tag is-danger has-text-weight-bold"
                >
                  {{ allAchDataFiltered.row.statusTranslated }}
                </span>
                <span
                  v-else-if="allAchDataFiltered.row.statusTranslated==='Entered into FAMIS'"
                  class="tag is-success has-text-weight-bold"
                >
                  {{ allAchDataFiltered.row.statusTranslated }}
                </span>
                <span
                  v-else-if="allAchDataFiltered.row.statusTranslated"
                  class="tag is-warning has-text-weight-bold"
                >
                  {{ allAchDataFiltered.row.statusTranslated }}
                </span>
              </template>

              <template slot="beforeTable">
                <label class="label">Filter by status</label>
                <div class="columns">
                  <div class="column">
                    <checkbox
                      v-model="openFilterValues"
                      label="Open — pending department"
                      :options="openDeptFilterOptions"
                    />
                  </div>
                  <div class="column">
                    <checkbox
                      v-model="transferredFilterValues"
                      label="Open — pending ACH admin"
                      :options="openFinanceFilterOptions"
                    />
                  </div>
                  <div class="column">
                    <checkbox
                      v-model="closedFilterValues"
                      label="Completed"
                      :options="closedFilterOptions"
                    />
                  </div>
                </div>
              </template>

              <template
                slot="humanReadableId"
                slot-scope="props"
              >
                <a
                  href="#"
                  @click.prevent="routeTo(props.row)"
                >{{ props.row.humanReadableId }}</a>
              </template>

            </v-client-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import StatusMixin from '@/pages/ach-request/StatusMixin';

import Vue from 'vue';
import { format, parseISO } from 'date-fns';
import { options }  from '@/config/tableOptions';

const tableColumns = [
  "humanReadableId",
  "submissionDateTranslated",
  "submitterVendorName",
  "payeeName",
  // "vendorData.name",
  "einOrSSN",
  "department.name",
  "statusTranslated",
  "daysSinceLastAction",
];

Vue.use(
  // ServerTable,
  {},
  false,
  'bulma'
);

export default {
  name: "AchRequestSelection",
  mixins: [ StatusMixin ],
  data() {
    return {
      totalCount: false,
      loading: false,
      query: '',
      filterValue: 'PayeeName',
      allAchData: [],
      openDeptFilterOptions: {
        'Pending department approval': 'Pending department approval',
        'Transferred — pending new department action': 'Transferred — pending new department action',
      },
      openFinanceFilterOptions: {
        'Verified and waiting for FAMIS input': 'Verified and waiting for FAMIS input',
        'Transferred to ACH admin': 'Transferred to ACH admin',
        'Rejected — pending ACH admin review': 'Rejected — pending ACH admin review',
      },
      closedFilterOptions: {
        'Entered into FAMIS': 'Entered into FAMIS',
        'Rejected by ACH admin': 'Rejected by ACH admin',
        'Deleted': 'Deleted',
      },
      tableColumns,
      tableOptions: options({
        headings: {
          humanReadableId: "Request ID",
          submissionDateTranslated: "Submission date",
          'submitterVendorName': "Vendor name",
          'payeeName': "Payee name",
          // 'vendorData.name': "Vendor name",
          einOrSSN: 'EIN/SSN',
          'department.name': "Current department",
          statusTranslated: 'Status',
          daysSinceLastAction: 'Days since last action',
        },
        columnsClasses: {
          submissionDate: 'date',
        },
        orderBy: {
          ascending: false,
          // column: 'humanReadableId',
          column: 'submissionDateTranslated',
        },
        sortable: [
          'humanReadableId',
          'submissionDateTranslated',
          'submitterVendorName',
          'payeeName',
          // 'vendorData.name',
          'einOrSSN',
          'department.name',
          'statusTranslated',
          'daysSinceLastAction',
        ],
        customSorting: {
          submissionDateTranslated: function(ascending) {
            // console.log('submissionDateTranslated custom sorting, ascending:', ascending);
            // ascending = !ascending;
            return function (a, b) {
              // console.log('a:', a, 'b:', b);
              let lastA = a.submissionDate;
              let lastB = b.submissionDate;
              if (ascending) {
                return lastA >= lastB ? 1 : -1;
              }
              return lastA <= lastB ? 1 : -1;
            };
          },
        },
        resizableColumns: true,
      }),
    };
  },
  computed: {
    openFilterValues: {
      get() {
        console.log('openFilterValues computed get is running');
        return this.$store.state.ach.openFilterValues;
      },
      set(value) {
        this.$store.commit('ach/setOpenFilterValues', value);
      },
    },
    transferredFilterValues: {
      get() {
        return this.$store.state.ach.transferredFilterValues;
      },
      set(value) {
        this.$store.commit('ach/setTransferredFilterValues', value);
      },
    },
    closedFilterValues: {
      get() {
        return this.$store.state.ach.closedFilterValues;
      },
      set(value) {
        this.$store.commit('ach/setClosedFilterValues', value);
      },
    },
    userDept() {
      return this.$store.state.users.curUserData.departmentId;
    },
    allAchDataFiltered() {
      // console.log('computed allAchDataFiltered, this.openFilterValues:', this.openFilterValues);
      let allFilterValues = [ ...this.openFilterValues, ...this.transferredFilterValues, ...this.closedFilterValues ];
      let data = [];
      for (let row of this.allAchData) {
        let newrow = { ...row };
        if (!allFilterValues.length || allFilterValues.includes(row.statusTranslated)) {
          if (newrow.department == null) {
            newrow.department = { name: 'Finance' };
          }
          if (newrow.daysSinceLastAction < 0) {
            newrow.daysSinceLastAction = 'NA';
          }
          data.push(newrow);
        }
      }
      return data;
    },
  },
  async fetch({ store }) {
    const { data } = await store.dispatch('ach/getAllAch', store.state.users.curUserData.departmentId);
    return Promise.resolve();
  },
  mounted(){
    let values = [ ...this.$store.state.ach.allAchData ];
    // console.log('Select.vue mounted, values:', values);
    for (let item of values) {
      item.statusTranslated = this.statusKey[item.status];
      item.submissionDateTranslated = format(parseISO(item.submissionDate), 'MM/dd/yyyy');
    }
    // if (this.loggedIn.type === 'AchAdministrator' || this.loggedIn.type.includes('AchAdministrator')) {
    //   this.closedFilterOptions.Deleted = 'Deleted';
    // }
    this.allAchData = values;
  },
  methods: {
    routeTo(row) {
      // console.log('routeTo is running, row:', row);
      this.$router.push('/ach-request/' + row.humanReadableId);
    },
  },
};

</script>

<style>

.input-checkbox fieldset legend {
  font-weight: bold !important;
}

</style>
